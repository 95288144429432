@import url("https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Saira:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --gray-400: #d9d9d9;
  --pink-900: #c64599;
  --pink-700: rgba(198, 69, 153, 0.2);
  --gray-500: #828589;
}

.ant-steps.ant-steps-navigation .ant-steps-item::before {
  background-color: var(--pink-900) !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--pink-900) !important;
  border-color: var(--pink-900) !important;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: var(--pink-900) !important;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: var(--pink-900) !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--pink-900) !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: white !important;
  border-color: var(--pink-900) !important;
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45) !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.45) !important;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: white !important;
  border-color: rgba(0, 0, 0, 0.45) !important;
}

.ant-form-item-required {
  flex-direction: row-reverse;
}

.ant-form-item-required::after {
  width: 0;
  margin: 0 !important;
}

.ant-input-outlined {
  border-color: var(--pink-900) !important;
  border-radius: 2px;
}

.messagesBorder {
  border-color: var(--gray-400) !important;
  border-radius: 2px;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--pink-900) !important;
  border-radius: 2px;
}

.ant-select .ant-select-arrow {
  color: var(--pink-900);
}

.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--pink-700);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--pink-900) !important;
  border-color: var(--pink-900) !important;
}

.ant-radio-wrapper .ant-radio-inner {
  border-color: gainsboro !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--pink-900) !important;
  border-color: var(--pink-900) !important;
}
.ant-checkbox .ant-checkbox-inner {
  border-color: gainsboro !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  background-color: var(--pink-900);
  border-radius: 2px;
}

.ant-input-outlined.ant-input-disabled,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-input-outlined[disabled] {
  color: black;
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector {
  color: black;
}

#monri-button-container .monri-lightbox-button-el {
  width: 50% !important;
  transition: all 2s ease;
  background-color: #292663;
  padding: 8px 0 8px 0;
  margin-top: 30px;
}

#monri-button-container .monri-lightbox-button-el:disabled span {
  background-color: #292663;
}

.disabled-monri-button {
  pointer-events: none;
  opacity: 0.5;
}
