.searchInput [class*="ant-input-outlined"] {
  border-color: var(--gray-400) !important;
}

.grayArrow [class*="ant-select-arrow"] {
  color: var(--gray-500) !important;
}

.selectPadding [class*="ant-select-selector"] {
  padding: 0 6px !important;
}
